


























































































































































































































































































































import { RequestPaging, timeFormat } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import config from '@/config'
import LsPagination from '@/components/ls-pagination.vue'
import vueEasyPrint from 'vue-easy-print'
import downloadExcel from 'vue-json-excel'
// import exDateTime from '@/components/ex-date-time.vue'
import {
    apiReportList, // 配送报表列表
    apiWarehouseReportList, // 站点报表
    apiReportDetailList // 配送详情列表
} from '@/api/delivery'
@Component({
    components: {
        LsDialog,
        LsPagination,
        downloadExcel,
        vueEasyPrint
    }
})
export default class Category extends Vue {
    PrintDialogVisible = false // 打印配送单弹窗is show
    config = config
    timeFormat = timeFormat
    $refs: any
    
    // 导出表格数据
    title: any = ''
    excelName: any = ''
    json_fields =  {
        "配送小区":'1',
        "客户地址":'2',
        "配送产品":'3',
        "客户信息":'4',
        "订单标签":'5'
    }
    // 汇总总数
    warehouse_total = 0
    DetailsForm: any = [] // 导出表格数据




    parintList: any = [] // 打印的表格数据
    deliveryman_name = '' // 打印数据中的配送员名称
    deliveryman_mobile = '' // 打印中的配送员手机号
    goods_type_lists: any = [] // 打印弹窗中的商品列
    warehouse = '' // 打印中的奶站

    // 查询条件
    queryObj: any = {
        delivery_date: '',
        status: '1'
    }
    // 查询条件
    detailQueryObj: any = {
        delivery_date: '',
        deliveryman_id: '',
        community_id: '',
        mobile: '',
        goods_id: '',
        warehouse_id: '',
        page_size: 10000
    }

    pager = new RequestPaging()

    warehousePager = new RequestPaging()
    apiReportList = apiReportList // 配送员报表api
    apiWarehouseReportList = apiWarehouseReportList // 站点报表api

    // 查询
    queryHandle(e: any) {
        this.getWarehouseList(1)
        this.getList(1)
        sessionStorage.setItem('delivery_date', this.queryObj.delivery_date)
        sessionStorage.setItem('status', this.queryObj.status)
    }
    // 获取配送单打印表格数据
    getReportDetailList() {
        this.warehousePager.loading = true
        this.pager.loading = true
        apiReportDetailList({...this.detailQueryObj, status: this.queryObj.status}).then((res: any) => {
            this.parintList = res.lists
            let DetailsForm:any = []
            
            res.lists.forEach((item:any, index:any) => {
                let obj = {
                    "1": item.address.community,
                    "2": item.address.address,
                    "3": item.order_goods.goods_snap.goods_name + "*" + item.every_num,
                    "4": item.address.contact + ':' + item.address.mobile,
                    "5": item.remark
                }
                DetailsForm.push(obj)
                
                console.log("item", item)
                
            })
            this.DetailsForm = [...DetailsForm]

            

            this.PrintDialogVisible = true
            this.warehousePager.loading = false
            this.pager.loading = false
        })
    }
    // 获取配送员报表列表
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiReportList,
            params: {
                delivery_record_type: '2',
                delivery_date: this.queryObj.delivery_date,
                status: this.queryObj.status
            }
        })
    }
    // 获取站点报表列表
    getWarehouseList(page?: number): void {
        page && (this.warehousePager.page = page)
        this.warehousePager.request({
            callback: apiReportList,
            params: {
                delivery_record_type: '1',
                delivery_date: this.queryObj.delivery_date,
                status: this.queryObj.status
            }
        }).then(res => {
            let total:any = 0
            res.lists.forEach((item: any) => {
                item.goods_type_lists.forEach((item_goods:any) => {
                    total += Number(item_goods.count)
                })
            })
            this.warehouse_total = total
        })
    }
    // 重置搜索
    reset() {
        let nowDate: any = new Date()
        let date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            date: nowDate.getDate()
        }

        if (parseInt(date.date) < 10) {
            date.date = '0' + date.date
        }
        this.queryObj.delivery_date =
            date.year + '-' + date.month + '-' + date.date
        sessionStorage.removeItem('delivery_date')
        sessionStorage.removeItem('status')
        this.queryObj.status = '1'
        this.getList()
        this.getWarehouseList()
    }
    // 初始化数据
    created() {
        let delivery_date = sessionStorage.getItem('delivery_date')
        let status = sessionStorage.getItem('status')
        if (delivery_date && status) {
            this.queryObj.delivery_date = delivery_date
            this.queryObj.status = status
        } else {
            let nowDate: any = new Date()
            let date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate()
            }

            if (parseInt(date.date) < 10) {
                date.date = '0' + date.date
            }
            this.queryObj.delivery_date =
                date.year + '-' + date.month + '-' + date.date
        }

        this.getList()
        this.getWarehouseList()
    }
    // 开启打印弹窗
    printOpen(row: any, type: Number) {
        this.goods_type_lists = row.goods_type_lists
        let goods_list:any = ['商品统计：']; 
        row.goods_type_lists.forEach((item:any, index:any, arr:any) => {
            console.log("item11", item)
            let name = item.order_goods.goods_snap.goods_name + '*' + item.count + 
               (index != arr.length - 1 ? ' | ' : '' ) 
            goods_list.push(name)
             
        })
        this.title = goods_list.join("")
        if (type == 1) { // 仓库
            console.log('type == 1', row)
            this.warehouse = row.warehouse.name
            this.deliveryman_name = ''
            this.deliveryman_mobile = ''
            this.detailQueryObj = {
                delivery_date: timeFormat(row.delivery_date),
                warehouse_id: row.warehouse_id,
                page_size: 10000
            }
            this.excelName = row.warehouse.name + '(' + timeFormat(row.delivery_date) + ')' 

        } else { // 配送员
            console.log('2', row)

            this.warehouse = row.warehouse.name
            this.deliveryman_name = row.deliveryman.name
            this.deliveryman_mobile = row.deliveryman.mobile
            this.detailQueryObj = {
                delivery_date: timeFormat(row.delivery_date),
                deliveryman_id: row.deliveryman_id,
                page_size: 10000
            }

            this.excelName = row.deliveryman.name + '-' + row.deliveryman.mobile + '('  + row.warehouse.name + '-' +  timeFormat(row.delivery_date) + ')'
        }

        this.getReportDetailList()
    }
    //打印
    PrintRow() {
        this.$refs.easyPrint.print()
    }
}
